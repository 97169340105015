body {
  margin: 0px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.main-container {
}

.content-container {
  display: flex;
  width: 100%;
  margin-top: 63px;
  height: calc(100vh - 63px);
}

.left-panel-container {
  width: 100%;
  overflow-y: hidden;
  scrollbar-gutter: stable;
}

.left-panel-container:hover {
  overflow-y: auto;
}

.hidden {
  opacity: 0 !important;
  position: absolute;
}

a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.icon {
  height: 30px;
  width: 30px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}

.icon:hover {
  background-color: hsl(0, 0%, 96%);
}

.icon-container {
  padding-left: 40px;
}

/* Navbar Styling */

.navbar-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  padding: 20px;
  z-index: 1000;
  background-color: white;
  padding-bottom: 0px;
}

.navbar-button {
  padding-right: 5px;
}

.navbar-btns {
  display: flex;
  text-align: left;
}

.navbar-title-container {
  display: flex;
  font-size: 24px;
  font-weight: 500;
}

.contact-icon-container {
  display: flex;
  justify-content: space-evenly;
}

.copy-icon::after {
  width: 100px;
  display: block;
}

.download-resume-icon {
  position: relative;
  bottom: 8px;
  height: 25px;
  width: 25px;
}

.download-resume-icon:hover {
  background-color: transparent;
}

/* Timeline Styling */

.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 14px;
  width: 100%;
  overflow-y: hidden;
  scrollbar-gutter: stable;
}

.timeline-container:hover {
  overflow-y: auto;
}

.milestone-container {
  width: 50px;
  margin: auto;
  position: relative;
}

.node-container {
  position: absolute;
  display: flex;
}

.node {
  position: absolute;
  background-color: lightgray;
  height: 50px;
  width: 50px;
  border-radius: 90%;
}

.date-right {
  position: absolute;
  left: 55px;
  top: 15px;
  width: max-content;
  font-weight: 300;
}

.date-left {
  position: absolute;
  right: 55px;
  top: 15px;
  width: max-content;
  font-weight: 300;
}

.edge {
  height: 175px;
  border-left: 1px solid black;
  margin-left: 25px;
}

.start-node {
  background-color: lightgreen;
}

.start-node::after {
  content: "";
}

.end-node {
  background-color: lightcoral;
}

.end-node::after {
  content: "";
}

.node-callout-container-left {
  display: flex;
  flex-direction: row-reverse;
}

.node-callout-container-right {
  display: flex;
  flex-direction: row;
}

.callout-left {
  right: 70px;
}

.callout-right {
  left: 70px;
}

.callout {
  background-color: hsl(0, 0%, 96%);
  position: absolute;
  width: 260px;
  height: fit-content;
  min-height: 50px;
  border-radius: 5px;
  box-shadow: 5px 5px 5px lightgray;
  padding: 10px;
  font-weight: 300;
  overflow-y: hidden;
  max-height: 125px;
  scrollbar-gutter: stable;
}

.callout:hover {
  overflow-y: auto;
}

.callout-title {
  font-weight: 600;
  padding-bottom: 5px;
}

.timeline-title-container {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
}

.callout-title-container {
  /* overflow-y: hidden; */
}

.resume-download-a {
  text-decoration: none;
  color: black;
  display: flex;
  height: 24px;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  bottom: 3px;
}

.resume-download-a:hover {
  background-color: hsl(0, 0%, 96%);
}

/* About me component styling */

.about-me-container {
  display: flex;
}

.about-me-content {
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px;
  width: 100%;
}

.about-me-title {
  font-weight: 600;
  font-size: 20px;
  margin-top: 0px;
}

.about-me-body {
  padding-left: 50px;
}

.transition-about-me-container {
  transition: opacity 0.2s;
}

.profile-photo-container {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}

.profile-photo {
  height: 100px;
  width: 100px;
  border-radius: 90%;
  padding-right: 10px;
}

.contact-me-link {
  color: gray;
  width: fit-content;
  border-radius: 5px;
  padding: 2px;
  cursor: pointer;
}

.contact-me-link:hover {
  background-color: hsl(0, 0%, 96%);
}

/* Projects Styling */
.projects-container {
  padding-left: 90px;
  margin-top: 40px;
  margin-right: 5px;
}

.projects-title {
  font-size: 20px;
  font-weight: 600;
}

.project-title {
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px;
  align-content: center;
}

.project-title-container:hover {
  /* font-weight: 600; */
}

.projects-content-container {
  padding-top: 10px;
  width: 95%;
}

.project-tool {
  height: 22px;
  position: relative;
  bottom: 6px;
  left: 6px;
  padding-top: 5px;
  padding-bottom: 1px;
}

.project-title-container {
  display: flex;
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.project-title-wrapper {
  display: flex;
}

.project-title-wrapper:hover {
  background-color: hsl(0, 0%, 90%);
  border-radius: 5px;
}

.project-container {
  background-color: hsl(0, 0%, 96%);
  padding: 6px;
  border-radius: 5px;
  box-shadow: 5px 5px 5px lightgray;
  margin-bottom: 20px;
}

.project-demo {
  text-align: center;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  font-weight: 500;
}

.project-demo:hover {
  font-weight: 600;
  background-color: hsl(0, 0%, 90%);
}

.demo-container {
  display: flex;
  justify-content: center;
  height: 25px;
  align-items: center;
}

.project-desc {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  height: 125px;
  overflow-y: hidden;
  scrollbar-gutter: stable;
}

.project-desc:hover {
  overflow-y: auto;
}

/* Contact Me Styline */

.contact-me-container {
  margin-left: 90px;
  font-size: 20px;
  margin-bottom: 100px;
}

.contact-me-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 75px;
  padding-bottom: 10px;
}

.contact-input-container {
  background-color: hsl(0, 0%, 96%);
  height: 100%;
  box-shadow: 5px 5px 5px lightgray;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
}

.input-email {
  margin-bottom: 20px;
  display: block;
  border: none;
  border-radius: 5px;
  height: 30px;
  width: 95%;
  margin-top: 15px;
  padding-left: 5px;
}

.input-message {
  margin-bottom: 10px;
  display: block;
  border: none;
  border-radius: 5px;
  height: 125px;
  width: 95%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-left: 5px;
  padding-top: 5px;
  resize: none;
}

.submit-container {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  height: 30px;
}

.submit-btn {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
}

.submit-btn:hover {
  font-weight: 600;
  background-color: hsl(0, 0%, 90%);
}

.message-failed-span {
  font-size: 16px;
  color: red;
  position: relative;
  top: 5px;
}

/* Loading Icon */

.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 30px;
}
.lds-ellipsis div {
  position: absolute;
  top: 9.33333px;
  width: 8.33333px;
  height: 8.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

/* Tool List Styling */
.tool-list-container {
  height: fit-content;
  width: 140px;
  position: absolute;
  background: hsl(0, 0%, 90%);
  right: 25px;
  top: 25px;
  box-shadow: 5px 5px 5px lightgray;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
}

.tool-list-item {

}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* Success Icon Styling */

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding-left: 2px;
  padding-bottom: 2px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

/* Mobile Styling */
@media (max-width: 1180px) {
  body {
    overflow: hidden;
  }

  input {
    font-size: 16px;
  }

  textarea {
    font-size: 16px;
  }

  .project-desc {
    height: 145px;
  }

  .content-container {
    display: block;
    overflow: auto;
    margin-top: 53px;
    height: calc(100vh - 50px);
  }

  .about-me-body {
    padding-left: 30px;
  }

  .about-me-content {
    padding-left: 10px;
    padding-right: 10px;
  }

  .resume-download-a {
    padding: 5px;
    top: 1px;
  }

  .navbar-container {
    padding: 5px;
  }

  .icon-container {
    padding-left: 5px;
  }

  .projects-container {
    width: 100%;
    padding-left: 0px;
    margin-right: 0px;
  }

  .projects-content-container {
    margin-left: auto;
    margin-right: auto;
  }

  .projects-title {
    padding-left: 10px;
  }

  .contact-me-container {
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .contact-input-container {
    margin-left: auto;
    margin-right: auto;
  }

  .contact-me-title {
    padding-left: 10px;
  }

  .callout {
    width: 120px;
  }

  .edge {
    height: 150px;
  }

  .timeline-title-container {
    margin-top: 50px;
  }

  .timeline-container {
    overflow-y: visible;
  }

  .timeline-container:hover {
    overflow-y: visible;
  }

  .left-panel-container {
    overflow-y: visible;
  }

  .left-panel-container:hover {
    overflow-y: visible;
  }
}
